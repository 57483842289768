import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import { ListType } from 'generated-types.d';

import { borderRadius, breakpoints, colors, fontSizes, space, textStyles } from 'utils/rebass-theme';

import LightBlueBlobSVG from 'assets/icons/bubbles/lists-light-blue-blob.svg';

export const DisabledItem = styled.span`
  width: 100%;
  font-size: 12px;
  color: ${colors.grey}
`;

export const BoldItem = styled.span`
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.floomMidnightBlue}
`;

export const Thumbnail = styled(Box)<{ imageUrl?: string }>`
  position: relative;
  display: table;
  overflow: hidden;
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: ${borderRadius.large};
  background-color: ${colors.middleGrey};
  background-image: ${({ imageUrl }) => !!imageUrl ? `url(${imageUrl})` : 'none'};
  background-size: cover;
  background-position: center;

  svg {
    display: ${({ imageUrl }) => !imageUrl ? 'block' : 'none'};
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ListCellTitle = styled.div<{ fontSize?: number }>`
  ${textStyles.body};
  font-size: ${({ fontSize }): string => !!fontSize ? `${fontSize}px` : '12px'};
  flex-grow: 2;
`;

export const ListCellSubtitle = styled(Box)<{ fontSize?: number }>`
  font-size: ${({ fontSize }): string => !!fontSize ? `${fontSize}px` : '12px'};
  color: ${colors.grey};
`;

export const ListItemSubTitle = styled.div`
  ${textStyles.body};
  color: ${colors.floomMidnightBlue};
  font-weight: 400;
`;

export const ListItemTitle = styled.div<{ fontSize?: number }>`
  ${textStyles.h5}
  color: ${colors.floomMidnightBlue};
  font-size: ${({ fontSize }): string => !!fontSize ? `${fontSize}px` : '14px'};
  margin-bottom: 3px;
  flex-grow: 2;
`;

export const ListsBlob = styled(LightBlueBlobSVG)<{ fill?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  z-index: -1;
  height: 25vw;
  min-height: 300px;

  path {
    fill: ${({ fill }) => fill || '#A0E8F4'};
  }
`;

export const ListCardWrapper = styled(Box)<{ padding?: string }>`
  position: relative;
  background: ${colors.uiFills};
  padding: ${({ padding }) => padding || '20px'};
  box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
  border-radius: ${borderRadius.large};
  width: 100%;
  margin-bottom: 20px;
  
  &:last-of-type {
    margin-bottom: 40px;
  }
`;

export const ListSectionHeading = styled(Box)`
  ${textStyles.footnote};
  text-transform: uppercase;
  font-weight: 400;
  color: ${colors.floomMidnightBlue};
  margin: 40px 0 20px 0;
`;

/**
 * List table styles
 */
export const ListHeadingsWrapper = styled(Box)`
  position: sticky;
  top: -1px;
  background: ${colors.uiFills};
  z-index: 10;
`;

export const ListColumnsWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ListColumnWrapper = styled(Flex)<{ customStyles: SerializedStyles }>`
  min-width: 140px;

  ${({ customStyles }) => customStyles};
`;

export const Columns = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ListGroupNameHeading = styled(Box)`
  margin-top: 10px;
  padding-left: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 20px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding-left: 0;
  }
`;

export const ListScrollWrapper = styled(Box)`
  width: 100%;
`;

export const ListItemWrapper = styled(Flex)<{ isNew: boolean; customStyles: SerializedStyles }>`
  position: relative;
  flex-direction: row;
  width: 100%;
  padding: ${space[2]}px;
  align-items: center;
  transition: background-color .2s ease-in-out;
  background-color: ${({ isNew }): string => isNew ? hex2rgba(colors.grey, .05) : 'none'};
  color: ${colors.floomMidnightBlue};

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding: ${space[2]}px 0;
    align-items: flex-start;
  }

  @media only screen and (max-width: ${breakpoints.large}) {
    border-bottom: 1px ${colors.middleGrey} solid;
  }

  &:last-child {
    border-bottom: none;
  }

  ${({ customStyles = '' }) => customStyles};
`;

export const ItemDetails = styled(Flex)<{ customStyles: SerializedStyles }>`
  align-items: center;
  flex-grow: 1;
  margin-left: ${space[3]}px;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoints.large}) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: ${breakpoints.small}) {
    margin-left: 12px;
  }

  ${({ customStyles = '' }) => customStyles};
`;

export const ItemTitle = styled(Flex)<{listType: ListType}>`
  width: ${({ listType }) => listType === ListType.Simple ? '75%' : '30%'};
  align-items: center;
  font-size: ${fontSizes.small}px;
  flex-grow: 2;

  @media only screen and (max-width: ${breakpoints.large}) {
    width: 100%;
    margin-bottom: ${space[1]}px;
  }
`;

export const MetaColumn = styled(Flex)<{ customStyles: SerializedStyles; isMetadata: boolean; isQuantity: boolean }>`
  min-width: 55px;
  width: 10%;

  @media only screen and (max-width: ${breakpoints.large}) {
    display: ${({ isMetadata }) => isMetadata ? 'none' : 'flex'};
    order: ${({ isQuantity }) => isQuantity ? '0' : '1'};
  }

  ${({ customStyles }) => customStyles};
`;

/**
 * List totals styles. Used for checkout / order views
 */
export const TotalsTable = styled(Box)<{ hideTopBorder?: boolean }>`
  font-size: 16px;

  ${({ hideTopBorder }) => hideTopBorder ? '' : `border-top: 3px solid ${colors.floomMidnightBlue};`}
`;

export const TotalRow: any = styled(Flex)`
  padding: 20px 15px;
  justify-content: space-between;
  align-items: center;

  + ${() => TotalRow} {
    border-top: 1px solid ${colors.middleGrey};
  }
`;

export const GrandTotalRow = styled(TotalRow)<{ sticky?: boolean }>`
  background: ${colors.uiFills};
  border-top: 2px solid ${colors.floomMidnightBlue};

  ${({ sticky = true }) => sticky ? `
    position: sticky;
    bottom: 0;
    z-index: 9;
  ` : ''}
`;

export const TotalValueColumn: any = styled(Box)`
  text-align: right;
  padding-left: 15px;
`;

export const TotalSubCopy = styled(Box)`
  ${textStyles.footnote};
  margin-top: 5px;
  font-weight: 400;
  color: ${colors.shade60};
`;

export const TotalToBeCalculatedColumn: any = styled(Box)`
  ${textStyles.footnote};
  text-align: right;
  padding-left: 15px;
  font-weight: 400;
  color: ${colors.shade60};
`;

/**
 * List footer styles
 */
export const FooterWrapper = styled(Flex)`
  background: ${colors.paleGrey};
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: ${space[3]}px;
  padding: 20px;
  margin-top: 20px;
  border-radius: ${borderRadius.large};
  width: 100%;

  @media only screen and (max-width: ${breakpoints.medium}) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
